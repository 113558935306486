<template>
  <PrimaryTemplate
    :step="step"
    :buttonFunction="submit"
    :mainTitle="'Work Experience'"
    :needBackButton="true"
    :backFunction="back"
    :loading="loading"
  >
    <template v-slot:toggleButton>
      <ToggleButton :text="'Looking for the first job'" v-model="firstWork" />
    </template>

    <div class="workplaces-wrapper" v-if="firstWork == false">
      <div class="headline-wrapper">
        <h2 class="card-heading">Previous companies</h2>
        <AddButtonComponent
          addText="a workplace"
          @click="handleNewWorkplaceAdd"
        />
      </div>
    </div>
    <div class="card-content-area">
      <p class="sub-text" v-if="Object.keys(workplaces).length === 0">
          Please indicate your previous place of work
      </p>
      <div
        class="single-card-wrapper"
        v-for="(value, name, index) in workplaces"
        :key="name"
      >
        <SingleWorkplace
          :index="index"
          :workplaceId="name"
          :workplaceName="value.workplaceName"
          :workplacePosition="value.workplacePosition"
          :workplaceStartWorkingDate="value.startWorkingDate"
          :workplaceEndWorkingDate="value.endWorkingDate"
          @update:modelValue="handleWorkplaceModelChange"
          @modelRemove="handleWorkplaceModelRemove"
        />
      </div>
    </div>

    <TextAreaInput
      name="add-extra-info"
      labelText="Why do you want to work at Azati?"
      placeholder="Type your message here"
      v-model="reason"
    />
    <DropdownInput
      labelText="How did you know about Azati"
      :options="infoSourceList"
      placeholderText="Choose one option"
      v-model="infoSource"
    />
    <div v-if="errorMessage" class="error-message red">
      {{ errorMessage }}
    </div>
  </PrimaryTemplate>
</template>
<script>
import { generateUID } from "../utils/generateUID";

import PrimaryTemplate from "@/layouts/PrimaryTemplate.vue";

import ToggleButton from "@/components/elements/ToggleButton.vue";
import DropdownInput from "../components/utility/DropdownInput";
import TextAreaInput from "../components/utility/TextAreaInput";
import AddButtonComponent from "../components/utility/AddButtonComponent";
import SingleWorkplace from "../components/utility/SingleWorkplace";
import { update } from "../api/formdata";

import useVuelidate from "@vuelidate/core";

export default {
  name: "Experience",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      step: "Experience",
      infoSourceList: [
        "Friends/Colleagues",
        "Social networks",
        "Official website",
        "Job search platforms",
      ],
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    workplaces: function () {
      return this.$store.getters.getWorkplaces;
    },
    firstWork: {
      get() {
        return this.$store.getters.getFirstWork;
      },
      set(value) {
        this.$store.dispatch("setFirstWork", value);
      },
    },
    reason: {
      get() {
        return this.$store.getters.getReason;
      },
      set(value) {
        this.$store.dispatch("setReason", value);
      },
    },
    infoSource: {
      get() {
        return this.$store.getters.getInfoSource;
      },
      set(value) {
        this.$store.dispatch("setInfoSource", value);
      },
    },
  },
  methods: {
    handleNewWorkplaceAdd() {
      this.$store.dispatch("updateWorkplace", {
        id: generateUID(),
        name: "",
        position: "",
        startWorkingDate: "",
        endWorkingDate: "",
      });
    },
    handleWorkplaceModelChange(payload) {
      this.$store.dispatch("updateWorkplace", payload);
    },
    handleWorkplaceModelRemove(id) {
      this.$store.dispatch("removeWorkplace", id);
    },

    async submit() {
      this.v$.$touch();
      let modifyLoading = this.modifyLoading;
      let setError = this.setError;
      let router = this.$router;

      if (!this.v$.$invalid) {
        modifyLoading(true);
        setError(null);

        let thisData = {
          workplaces: this.toArray(this.workplaces),
          firstWork: this.firstWork,
          reason: this.reason,
          infoSource: this.infoSource,
        };
        let currentData = JSON.parse(localStorage.getItem("data"));
        currentData.data.push(thisData);

        await update(currentData).then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            modifyLoading(false);

            let thisDataStringified = JSON.stringify(currentData);
            localStorage.setItem("data", thisDataStringified);
            router.push({ path: "/technology-stack" });
          } else {
            modifyLoading(false);
            if (response.status == 400) {
              setError(
                "Validation error! Please check the accuracy of the entered data!"
              );
            } else {
              setError("Form submission error! Please try again later!");
            }
            console.log("Error: ");
            console.log(response.status);
          }
        });
      }
    },
    back() {
      let currentData = JSON.parse(localStorage.getItem("data"));
      currentData.data.pop();
      let thisDataStringified = JSON.stringify(currentData);
      localStorage.setItem("data", thisDataStringified);

      this.$router.push({ path: "/education" });
    },
    toArray(data) {
      let array = [];
      Object.values(data).forEach((val) => {
        array.push(val);
      });
      return array;
    },
    modifyLoading(loading) {
      this.loading = loading;
    },
    setError(message) {
      this.errorMessage = message;
    },
  },
  components: {
    PrimaryTemplate,
    DropdownInput,
    TextAreaInput,
    ToggleButton,
    AddButtonComponent,
    SingleWorkplace,
    // CustomDropdownComponent,
    // Workplace,
    // AddButtonComponent,
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
.textarea-input-wrapper {
  margin-bottom: 25px;
}

.card-heading {
  color: $text-black;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 25px;
  text-align: left;
  width: 100%;
  @media (min-width: 320px) and(max-width: 375px) {
    font-size: 16px;
  }
}

.headline-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 320px) and(max-width: 455px) {
    flex-direction: column;

    .card-heading {
      margin-bottom: 10px;
    }

    .add-button-wrapper {
      max-width: 100%;
      justify-content: flex-start;
      margin-bottom: 10px;
    }
  }
}

.card-content-area {
  width: 100%;
  margin-bottom: 40px;

  .sub-text {
    color: $text-grey;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    min-height: 20px;
    display: flex;
  }
}

.workplaces-wrapper {
  width: 100%;
}
.red {
  color: red;
}
:disabled {
  opacity: 0.4;
}
</style>
