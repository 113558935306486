<template>
  <div class="single-element-wrapper">
    <h3 class="single-element-heading">
        Workplace #{{ index + 1 }}
      {{ workplaceName.length !== 0 ? `- ${workplaceName}` : "" }}
    </h3>
    <TextInput
      name="name"
      labelText="Company name"
      placeholder="Enter company name"
      v-model="name"
      :validationMessage="errorMessages.name"
    />
    <TextInput
      name="position"
      labelText="Position"
      placeholder="Enter job title"
      v-model="position"
      :validationMessage="errorMessages.position"
    />
    <div class="dates-wrapper">
      <UniversalMonthPicker
        labelText="Start date"
        placeholder="Choose date"
        v-model="startWorkingDate"
      />
      <UniversalMonthPicker
        labelText="End date"
        placeholder="Choose date"
        v-model="endWorkingDate"
      />
    </div>
    <div class="remove-button" @click="handleModelRemove">
        Delete workplace
    </div>
  </div>
</template>

<script>
import TextInput from "../utility/TextInput";
import UniversalMonthPicker from "../utility/UniversalMonthPicker";

import useVuelidate from "@vuelidate/core";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { maxLength } from "@vuelidate/validators";

export default {
  name: "SingleWorkplace",
  props: {
    index: Number,
    workplaceId: String,
    workplaceName: String,
    workplacePosition: String,
    workplaceStartWorkingDate: String,
    workplaceEndWorkingDate: String,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      id: this.workplaceId,
      name: this.workplaceName,
      position: this.workplacePosition,
      startWorkingDate: this.workplaceStartWorkingDate,
      endWorkingDate: this.workplaceEndWorkingDate,
    };
  },
  computed: {
    workplaceModel: function () {
      return {
        id: this.id,
        name: this.name,
        position: this.position,
        startWorkingDate: this.startWorkingDate,
        endWorkingDate: this.endWorkingDate,
      };
    },
    errorMessages: function () {
      return {
        name: getErrorMessage(this.v$.name.$errors),
        position: getErrorMessage(this.v$.position.$errors),
      };
    },
  },
  watch: {
    workplaceModel: function () {
      this.handleModelChange();
    },
  },
  methods: {
    handleModelRemove() {
      this.$emit("modelRemove", this.id);
    },
    handleModelChange() {
      this.$emit("update:modelValue", this.workplaceModel);
    },
  },
  components: {
    TextInput,
    UniversalMonthPicker,
  },
  validations() {
    return {
      name: {
        maxLength: maxLength(50),
        $autoDirty: true,
      },
      position: {
        maxLength: maxLength(50),
        $autoDirty: true,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/single-element-card";
</style>
